body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,body, #root, .App, .App > div{
  height: 100%;
  width: 100%
}
.App > div{
  display: flex;
  flex-direction: column;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*::-webkit-scrollbar
{
  width: 10px;
  height: 10px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}

.custom-collapse {
  background: white;
}

.App .status{
  position: absolute;
  padding: 5px;
  border-radius: 5px;
  box-shadow: black 0 0 5px;
  display: inline-block;
  height: 20px;
  width: 200px;
  background: white;
  text-align: center;
  line-height: 20px;
  bottom: 10px;
  z-index: 9999;
  left: 50%;
  transform: translate(-50%, 0);
}
.status-bar-top{
  background-color: #252627;
  border: 1px solid white;
  position: absolute;
  z-index: 111111;
  height: 46px !important;
  width: 270px !important;
  flex-direction: row;
  font-size: 14px;
  border-radius: 5px;
  top: 7px;
  right: 200px;
  color: white;
  padding: 2px 8px;
  box-sizing: border-box;
}

.clear-btn {
  position: fixed;
  top: 20px;
  right: 180px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: red; /* Bootstrap blue color */
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000; /* Make sure it's on top of other elements */
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.download-btn {
  position: fixed;
  top: 20px;
  right: 370px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff; /* Bootstrap blue color */
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000; /* Make sure it's on top of other elements */
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.install-btn {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff; /* Bootstrap blue color */
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000; /* Make sure it's on top of other elements */
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.install-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.install-btn i {
  font-size: 20px;
}

.install-btn:focus {
  outline: none;
}

/* Hide the button on small screens if not needed */
@media (max-width: 768px) {
  .install-btn {
    font-size: 14px;
    padding: 8px 15px;
  }
}