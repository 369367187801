.DashboardContainer {
  overflow: auto;
  background: #f0f2f5
}

.drawingsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 20px;
}
.drawings {
  overflow: auto;
  flex-grow: 1;
}

.drawings-inner {
  flex-wrap: wrap;
  display: flex;
  justify-content: left;

}

.newDrawingCard, .newProjectCard,
.projectCard ,
.drawingCard {
  height: 320px !important;
  margin: 10px;
  display: flex;
  flex-direction: column;
  width: 247px;
}

.svg-drawing, .svg-project{
  position: relative;
  z-index: 100;
  width: 247px;
  height: 160px;
}
.img-drawing{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 10rem;
}


.newDrawingCard, .newProjectCard{
  cursor: pointer;
  background:#c5e4ff;
  margin: 10px;
}

.newDrawingCard .ant-card-body, .newProjectCard .ant-card-body{
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newDrawingCard img {
  width: 60%;
  margin: auto;
}

.newProjectCard img {
  width: 60%;
  margin: auto;
}


.backBtn {
  margin-right: 10px;
  color: black
}

.header-block{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-card .ant-card-body {
   height: 52px;
 }

.drawing-image-preview{
  border: 1px solid;
}

.caching{
  font-size: 10px;
  margin-left: 45px;
}

.cache-button{
  cursor: pointer;
}