.close-button {
    top: 10px;
    right: 10px;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: grey;
    text-align: center;
    line-height: 50px;
    font-size: 27px;
    color: white;
    cursor: pointer;
}

.modal-background {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.settings-container {
    width: 90%;
    height: 90%;
    position: absolute;
    background: white;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    padding: 30px;
    box-shadow: 0 0 10px black;
    border-radius: 20px;
}

.sidebar {
    background: #252627 !important;
    height: inherit;
    position: relative;
    border-top: 1px solid #101010;
}

.ant-layout-content {
    background: #fff;
}

.sidebar .ant-layout-sider-children {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar h3 {
    color: #fff;
}

.main-content {
    height: inherit;
}

.manage-cat-button {
    margin-top: 1rem;
}

.logout-btn {
    position: absolute;
    bottom: 4rem;
    background: #18191b;
}

.back-btn {
    position: absolute;
    bottom: 8rem;
}

.logout-btn span {
    color: white;
}

.logout-btn svg {
    color: #fff;
    width: 0.8rem;
    height: 0.8rem;
}

.logo {
    width: 180px;
}

.logo button {
    padding: 0;
    display: inline-block;
    height: 20px;
    vertical-align: middle;
    margin: 5px 0;
}


/*Toolbar*/
.toolbar {
    background: #252627;
    position: relative;
    display: flex;
    height: 60px;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    min-height: 60px;
    border-bottom: 1px solid;
}

.logo a:hover {
    border-bottom: none !important;
}

.logo-heading {
    background: none;
    border: none;
    cursor: pointer;
}

.setting-button {
    opacity: 0.5;
    cursor: pointer;
}

.setting-button svg {
    color: #fff;
    width: 1.25rem;
    height: 1.25rem;
}

.toolbar li {
    margin-left: 0rem !important;
    margin: 0;
    margin-inline: 0 !important;
}

.toolbar h3 {
    margin: 0;
    color: #fff;
}

.zoom-box {
    background-color: #252627;
    color: white;
    width: 140px;
}

.drawing-name {
    display: flex;
    color: #fff;
    text-transform: capitalize;
}

.drawing-name p {
    margin-top: 0;
    font-weight: 700;
}

.drawing-name .description {
    padding-left: 10px;
    font-weight: 400;
}

.breadcrumbs-container {
    flex-grow: 1;
    color: white;
}
.breadcrumb{
    cursor: pointer;
}

.breadcrumbs {
    position: relative;
    height: 20px;
}

.breadcrumbs-item {
    color: #fff !important;
    font-size: 14px;
    font-weight: 500;
    background: none;
    border: none;
    cursor: pointer;
}

.ant-breadcrumb-separator {
    color: #fff !important;
}

/*Zoom box*/
.main-zoom-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 70px;
    border: 1px solid white;
    padding: 2px 0;
    border-radius: 5px;
}

.zoom-slider {
    width: 50%;
}

.zoom-box .ant-card-body {
    padding: 0 !important;
}


/*Workplace.css*/

@import "https://pro.fontawesome.com/releases/v5.10.0/css/all.css";

.parentContainera {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: hidden;
}

.items {
    padding: 20px;
}

.itemContainer {
    width: 160px !important;
    height: auto;
    padding: 5px !important;
    border: solid 1px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: -1px;
    position: relative;
}

.itemContainer img {
    width: 50px !important;
    height: 50px !important;
}

.itemContainer .labelContainer {
    font-size: small;
    text-align: center;
}

.itemContainer .delete-button-item svg {
    width: 17px;
    height: 15px;
    margin-top: 7px;
    margin-left: -7px;
}

.itemContainer .itemSizes {
    padding: 5px
}

.itemContainer .itemSizes button {
    margin-right: 5px
}

.ant-collapse-content-box {
    overflow: auto;
}


.canvasWrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100%;
}

html {
    --scroll-width: 11px;
}

.vertical-scroll,
.horizontal-scroll {
    position: absolute;
    background: transparent;

    > div {
        background: transparent;
        position: absolute;
    }
}

.vertical-scroll {
    top: 0;
    right: 0;
    bottom: var(--scroll-width);
    width: var(--scroll-width);
    overflow-y: auto;
    overflow-x: hidden;

    > div {
        height: 500px;
        width: 100%;
    }
}

.horizontal-scroll {
    left: 0;
    bottom: 0;
    right: var(--scroll-width);
    height: var(--scroll-width);
    overflow-x: auto;
    overflow-y: hidden;

    > div {
        width: 500px;
        height: 100%;
    }
}


.canvasContainer {
    overflow: hidden;
    /*width: 100%;*/
    /*height: 100%;*/
    flex-grow: 1;
    background: #f1f1f1;
}

.canvasContainer::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.canvasContainer::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

/*.actionsMenu-outer {*/
/*  background-color: #f9f9f9;*/
/*}*/

/*.actionsMenu-outer .ant-drawer-body{*/
/*  padding: 0;*/
/*  background-color: #f9f9f9;*/
/*}*/

/*.actionsMenu {*/
/*  margin-left: 32px;*/
/*  position: absolute;*/
/*  right: 0;*/
/*  top: 65px;*/
/*  padding: 30px 10px;*/
/*  background-color: #f9f9f9;*/
/*  z-index: 51;*/
/*  height: calc(100vh - 130px);*/
/*  overflow: scroll;*/
/*}*/

/*.actionsMenu > button {*/
/*  display: inline-block;*/
/*  margin: 5px 0;*/
/*  min-width: 50px;*/
/*  padding: 5px;*/
/*}*/

/*.actionsMenu > button:hover {*/
/*  opacity: 0.9;*/
/*}*/

.arrow-btn {
    position: absolute;
    left: 0;
    top: 40%;
    height: 6rem;
    width: 1rem;
    padding: 0;
    border-radius: 0 4rem 4rem 0;
}

.arrow-btn-open {
    position: absolute;
    right: 7px;
    top: 40%;
    height: 6rem;
    width: 1rem;
    padding: 0;
    border-radius: 4rem 0 0 4rem;
}

.arrow-btn-sidebar {
    position: relative;
    top: 45%
}

.labelInput {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.block-btn {
    display: block !important;
}

.subcategoryContainer {
    background-color: #e4e6ee;
    overflow: hidden;
    /*border-radius: 3px;*/
    /*margin-bottom: 5px;*/
    padding: 5px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ant-collapse-header-text,
.ant-collapse-expand-icon {
    color: white;
    white-space: wrap;
}

.objectTooltip {
    display: none;
    position: absolute;
    background-color: black;
    color: white;
    padding: 3px;
    font-size: 12px;
    line-height: 1.5;
}

.sidebar {
    width: 75px;
    display: flex;
    height: inherit;
    min-height: 0;
    z-index: 15;
}

.innerSidebar {
    display: flex;
    flex-direction: column;
    overflow: visible;
    position: relative;
    width: 75px;
    background: #18191b;
    color: #a3a3a4;
    z-index: 15;
}

.sidebar .item {
    height: 72px;
    position: relative;
    width: 75px;
    transition: opacity 0.15s ease-in-out;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.sidebar .selected {
    color: white;
    background: #252627;
    cursor: default;
}

.sidebar .item .icon {
    height: 24px;
    width: 24px;
    align-items: center;
    box-sizing: border-box;
    display: inline-flex;
}

.sidebar .item .title {
    text-align: start;
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 0.8rem;
    margin-top: 5px;
    overflow-wrap: break-word;
    text-align: center;
    display: block;
    max-width: 100%;
}

.subMenu {
    background: #252627;
    width: 200px;
    min-width: 200px;
    color: white;
    height: inherit;
    padding: 0 10px;
    position: relative;
    z-index: 1;
    transition: width 0.3s;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #101010;
    border-right: 1px solid black;
}

.subMenu > div {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.subMenu h3 {
    margin: 5px 0;
}

.subMenu .ant-drawer-body {
    width: 100%;
    padding: 0;
    background: #252627;
    color: white;
}

.subMenu-inner {
    width: 100%;
    padding: 20px;
}

.subMenu-open, .subMenu-close {
    background-color: #252627;
    color: #fff;
    position: absolute;
    left: 100%;
    height: 6rem;
    padding: 0;
    border-radius: 0 4rem 4rem 0;
    z-index: 0;
    top: 50%;
    margin-top: -3rem;
    border: 1px solid black;
    border-left: 0;
}

.shapesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.shape {
    flex: 1 1 60px;
}

.shape img {
    width: 60px;
    height: 60px;
}

.table-model-inner button {
    margin: 5px;
}

.table-model-inner {
    margin-left: 25px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.modal-custom-input {
    width: 40px;
    height: 30px;
}

.table-modal-container {
    width: 60%;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.pdf-modal-container {
    width: 60%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.no-spin {
    -moz-appearance: textfield;
}

.text-edit-bar {
    position: absolute;
    top: 0.25rem;
    left: 30%;
    z-index: 50;
}

.delete-button-item {
    width: 25px !important;
    height: 23px;
    padding: 0;
    position: absolute;
    top: 0px;
    right: 0px;
}

.edit-button-item {
    width: 25px !important;
    height: 23px;
    padding: 0;
    position: absolute;
    top: 0px;
    right: 1.5rem;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
}

.delete-button-item svg {
    color: red;
}

.edit-button-item svg {
    color: blue;
}

.ant-notification-notice {
    padding: 10px !important;
}

.subcategory-block .ant-collapse {
    background-color: #252627;
}

.ant-collapse-content-box {
    padding: 5px !important;
}

.ant-drawer-content-wrapper {
    width: 100%
}

.canvas-tooltip {
    display: none;
    position: absolute;
    background-color: black;
    color: white;
    padding: 3px;
    font-size: 12px;
    line-height: 1.5;
}

.toolbars-container {
    background: #252627;
    color: white;
    padding-top: 1px;
    display: flex;
    position: relative;
}

.tool-button {
    color: black;
}

#canvas-and-toolbars {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;
}

#objects-toolbar {
    flex-grow: 1;
}

.tools > .tool.active > .tool-container {
    width: 100%;
}

/*Text Editor*/
.ant-card-body {
    padding: 8px !important;
}

.button-bg {
    background: grey;
}

.bold-text {
    font-weight: bold;
}

.underline-text {
    text-decoration: underline;
}

.italic-text {
    font-style: italic;
}

.canvas-container {
    width: 100% !important;
}


.ant-collapse {
    margin-right: 5px;
    margin: 10px 0;
    overflow: auto;

    .ant-collapse {
        margin: 0 0;
        margin-right: 0;
        border: 0;
        border-radius: 0;
        background: #464646;

        .ant-collapse-header {
            padding-left: 15px !important;
            white-space: wrap;
        }


        > .ant-collapse-item:last-child > .ant-collapse-header {
            border-radius: 0 !important;
        }

        > .ant-collapse-item:last-child {
            border-radius: 0 !important;
            border-bottom: 0;
        }
    }

}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
    white-space: pre-wrap;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 5px 5px;
}

.ant-collapse-content-box {
    padding: 0 !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
    padding-inline-end: 5px !important;
}

.drawings-tabs-container {
    height: 30px;
    display: flex;
    border-top: 1px solid #252627;
}

.drawings-tabs {
    overflow: hidden;
    flex-grow: 1;
    white-space: nowrap;
    background: #18191b;
    font-size: 16px;
}

.drawings-tab {
    cursor: pointer;
    display: inline-block;
    width: 150px;
    background: #18191b;
    text-overflow: ellipsis;
    color: #fff;
    white-space: nowrap;
    padding: 0 5px;
    height: 30px;
    max-height: 30px;
    overflow: hidden;
    line-height: 30px;
    /*border: 1px solid #a3a3a4;*/
    /*margin-right: -6px;*/
    /*border-radius: 6px 6px 0 0;*/
    border-right: 1px solid black;
}

.slide-left {
    width: 40px;
    border-left: 1px solid #a3a3a4;
    text-align: center;
    min-width: 40px;
    box-sizing: border-box;
    background-color: aliceblue;
    line-height: 30px;
    color: #a3a3a4;
    border: 1px solid #a3a3a4;
    background: #18191b;
    border-width: 1px 1px 0 1px;
}

.slide-right {
    width: 40px;
    border-left: 1px solid #252627;
    text-align: center;
    min-width: 40px;
    box-sizing: border-box;
    background-color: aliceblue;
    line-height: 30px;
    color: #a3a3a4;
    border: 1px solid #a3a3a4;
    background: #18191b;
    border-width: 1px 1px 0 0;
}

.drawings-tab.active {
    background-color: #555;
    cursor: default;
}

.save-button {
    display: inline-flex;
    flex-direction: column;
    font-size: 10px;
    color: white;
    text-align: center;
    border: 1px solid white;
    padding: 0;
    width: 70px;
    height: 47px;
    border-radius: 5px;
    position: relative;
    padding-top: 5px;
    box-sizing: border-box;
}

.save-button.active {
    cursor: pointer;
}

.save-button .title {
    z-index: 1;
}

.save-button .icon-holder {
    z-index: 1;
}

.save-button .icon-spin {
    width: 20px;
    height: 21px;
    display: inline-block;
    overflow: hidden;
    font-size: 20px;
}
.save-button .icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    overflow: hidden;
    font-size: 15px;
}

.saving-progress {
    position: absolute;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
    border-radius: 5px;
    background: rgb(5 141 14);
    background: linear-gradient(90deg, rgb(5 141 14) 0%, rgba(9, 121, 116, 1) 100%, rgba(75, 225, 255, 1) 100%);
    transition: all 0.1s;
}

#canvas-toolbar > .tool.active{
    border-left: 1px solid black;
}



@media  (max-width: 1023px) {

    .subMenu {
        width: 200px;
        left: 75px;
        min-width: 200px;
        position: absolute;
        bottom: 0;
        top: 0;
        z-index: 22;
    }
}








.canvas-loader{
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 40px;
    text-shadow: 0 0 2px black;
    color: white;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    margin: -25px;
}
.canvas-saver, .canvas-saver-error{
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 20px;
    text-shadow: 0 0 2px black;
    color: white;
    height: 25px;
    width: 25px;
    text-align: center;
    line-height: 25px;
}
.canvas-saver{
    animation: Pulsate 2s linear infinite;
}


@keyframes Pulsate {
    from { opacity: 1; }
    50% { opacity: 0; }
    to { opacity: 1; }
}

.versions-panel{
    overflow-y: auto;
}

.version-title{
    margin: 0;
    margin-top: 10px;
}
.version-title{
    margin: 5px;
}
.versions-panel .version-image {
    box-shadow: black 0 0 2px;
}
.version-image{
    max-height: 159px;
    background: white;
    max-width: 100%;
}

.version-container{
    position: relative;
}

.version-remove-button{
    position: absolute;
    top: 5px;
    right: 0;
    cursor: pointer;
}

.sync-status, .online-status, .offline-status{
    display: inline-block;
    border-radius: 20px;
    height: 20px;
    width: 20px;
    margin: 5px;
    background: grey;
    box-shadow: 0 0 2px black;
    vertical-align: middle;
}

.sync-status{
    background-color: yellow;
}
.online-status{
    background-color: green;
}
.offline-status{
    background-color: red;
}






.spacer{
    flex-grow: 1;
}
.tools {
    display: flex;
    flex-direction: row;
    height: 85px;
}
.tools > .tool {
    margin-right: 5px;
}
.tools .tool-button svg {
    margin: 6px !important;
}
.tools .tool-button {
    border: 1px solid grey;
    border-radius: 4px;
    background-color: aliceblue;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    margin-bottom: 5px;
    margin-right: 8px;
    padding: 0;
    text-align: center;
    min-width: 36px;
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
}
.tools .fas ,
.tools .fa {
    width: 36px;
}
.tools .tool-button-icon {
    width: 36px;
}
.tools .tool {
    display: inline-flex;
    flex-direction: column;
    font-size: 13px;
    font-family: Arial, sans-serif;
}
.tools .tool > div {
    display: flex;
}
.tools .tool > label {
    font-size: 8px;
    height: 21px;
    line-height: 10px;
    max-width: 44px;
    display: inline-flex;
    overflow: hidden;
    align-items: self-end;
    margin-bottom: 1px;
}
.tools .tool label span {
    display: inline-block;
    padding: 1px;
}
.tools .tool input:disabled,
.tools .tool label input:disabled + .tool-button,
.tools .tool label input:disabled + .tool-button-icon {
    cursor: default;
    opacity: 0.5;
    background-color: lightgrey;
    color: grey;
}
.tools .tool label input:disabled + .tool-button svg path,
.tools .tool label input:disabled + .tool-button-icon svg path {
    fill: grey;
}
.tools .tool label input:checked + .tool-button,
.tools .tool label input:checked + .tool-button-icon {
    background: #0075ff;
    color: white;
    border: none;
}
.tools .tool select {
    height: 36px;
    margin-bottom: 5px;
    margin-right: 4px;
}
.tools .tool option {
    height: 36px;
}
.tools .tool input {
    margin-right: 5px;
    height: 36px;
    box-sizing: border-box;
}
.tools .tool input[type=color] {
    width: 36px;
}

.tools .tool input[type=number] {
    width: 75px;
    padding-right: 0;
}
.tools .tool input[type=range] {
    margin: 0 5px;
}
.tools .tool input[type=checkbox] {
    width: 36px;
    margin: 0;
}
.tools .tool input[type=radio] {
    width: 36px;
    margin-top: 0;
}



.tool-container{
    position: relative
}

.chessboard {
    /*background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAABlBMVEX////09PQtDxrOAAAAE0lEQVQI12P4f4CBKMxg/4EYDAAFkR1NiYvv7QAAAABJRU5ErkJggg==');*/
    background-image:
            linear-gradient(45deg, grey 25%, transparent 25%),
            linear-gradient(-45deg, grey 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, grey 75%),
            linear-gradient(-45deg, transparent 75%, grey 75%);
    background-size: 20px 20px;
    background-position: 2px 2px, 2px 12px, 12px -8px, -8px 2px;
}

.color-choices {
    position: absolute;
    top: 100%;
    width: 288px;
    padding: 5px;
    z-index: 11;
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    background: white;
    box-shadow: 0 0 5px black;
    border-radius: 5px;
    margin-top: 2px;
    margin-left: -3px;
}

.color-choices:before {
    left: 15px;
    content: "";
    position: absolute;
    background: white;
    box-shadow: 0 0 5px black;
    top: -5px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    z-index: -2;
}

.color-choices:after {
    content: "";
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    z-index: -1;
}

.color-choices span {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: inline-block;
    border: 1px solid grey;;
}

.tools .tool-button.tool-button-danger {
    background-color: #ff6666;
}
.tool-container.disabled {
    opacity: 0.2;
}

.tools  {
    position: relative;
}
.tools > .tool  {
    margin: 0;
}

.tools > .tool > .tool-container {
    position: absolute;
    top: 21px;
    position: absolute;
    border-top: 1px solid black;
    left: 0;
    display: none;
    z-index: 1;
    padding-left: 5px;
}
.tools > .tool.active > .tool-container{
    display: flex;
}
.tools > .tool.active > label{
    background-color: #252627;
    cursor: default;
    height: 9px;
    border-bottom: 0;
}

.tools > .tool > label {
    z-index: 2;
    border: 1px solid;
    margin: 0;
    padding: 6px;
    height: 8px;
    border-top: 1px solid #101010;
    cursor: pointer;
    max-width: none;
    font-size: 13px;
    padding: 6px 20px;
    display: inline-block;
    background: #18191b;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 9px;
    height: 8px;
    /*border: 1px solid #a3a3a4;*/
    /*margin-right: -6px;*/
    /*border-radius: 6px 6px 0 0;*/
    border: 0;
    border-right: 1px solid black;
    margin-right: 0;
    border-top: 1px solid #101010;
    border-bottom: 1px solid #101010;
}
#canvas-toolbar {
    width: 230px;
}
#demo-toolbar {
    width: 210px;
}






/*context-menu*/
.context-menu {
    position: absolute;
    display: none;
    user-select: none;
    z-index: 222;
}
.context-menu ul {
    list-style: none;
    padding: 0;
    box-shadow: 0 0 4px black;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgb(64 64 64 / 5%);
    padding: 0;
}
.context-menu hr  {
    width: 100%;
}
.context-menu li  {
    border: 0;
    padding: 10px 20px;
    width: 200px;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: unset;
    color: #000;
    font-weight: 500;
}
.context-menu li:first-child {
    border-radius: 10px 10px 0 0;
}
.context-menu li:last-child {
    border-radius: 0 0 10px 10px ;
}
.context-menu li.action:hover {
    background:#f1f3f7;
    color: #4b00ff;
    cursor: pointer;
}

.context-menu li.trash > a:hover {
    color: red;
}