.settings-inner-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  height: 100%;
}

.main-heading {
  display: flex;
  justify-content: space-between;
}

.category-block {
  margin-top: 2rem;
  padding: 1rem;
  overflow: scroll;
}

.button {
  margin-left: 4px;
  background: none !important;
}
.button svg {
  width: 0.75rem;
  height: 0.75rem;
}
.category-row {
  display: flex;
  justify-content: space-between;
}
.category-row .ant-form{
  width: 80%
}
.category-row h4 {
  width: 80%;
  margin: 0;
}

.subcategoryBox {
  background-color: #e4e6ee;
  overflow: hidden;
  border-radius: 3px;
  margin: 10px 0;
  padding: 5px;
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.custom-collapse span {
  color: rgb(24, 22, 22);
}

.custom-collapse svg {
  color: rgb(24, 22, 22);
}

.edit-buttons {
  display: flex;
  flex-direction: column;
}

.edit-button svg {
  color: blue;
}

.delete-button svg {
  color: red;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
.ant-popover-content{
  width: 320px ;
}
